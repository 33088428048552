import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { AboutPage } from '../components/pages';

const About: React.FC = () => {
  const pageData = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { eq: "about" } }) {
        frontmatter {
          primaryColor
          secondaryColor
          title
          subtitle
          heroText
          cardTitle1
          cardText1
          cardTitle2
          cardText2
          cardTitle3
          cardText3
          paragraph1
          paragraph2
        }
      }
    }
  `);

  return <AboutPage data={pageData.markdownRemark.frontmatter} />;
};

export default About;
